/* eslint-disable */

<template>
  <div>
    <component
      :is="exerciseOneComponent"
      ref="exerciseOne"
      :data="{ activeUserInfo: activeUserInfo }"
      v-if="currentSimulationExercise === 1"
      v-on:simulation_exercise_correct="sendSimulationFullCorrect"
      v-on:simulation_exercise_getting_there="sendExerciseGettingThere"
      v-on:simulation_exercise_getting_there_bad="sendExerciseGettingThereBad"
    />

    <component
      :is="exerciseTwoComponent"
      ref="exerciseTwo"
      :data="{ activeUserInfo: activeUserInfo }"
      v-if="currentSimulationExercise === 2"
      v-on:simulation_full_correct="sendSimulationFullCorrect"
      v-on:simulation_exercise_getting_there="sendExerciseGettingThere"
      v-on:simulation_exercise_getting_there_bad="sendExerciseGettingThereBad"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      currentSimulationExercise: 1,
      totalExercises: 2,
      totalExerciseScores: [5, 5],
      exerciseNames: ['Exercise One', 'Exercise Two'],
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    nextExercise() {
      this.currentSimulationExercise++;

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    sendSimulationFullCorrect(score) {
      return this.$emit('simulation_full_correct', score);
    },
    sendExerciseCorrect(score) {
      return this.$emit('simulation_exercise_correct', score);
    },
    sendExerciseGettingThere(score) {
      return this.$emit('simulation_getting_there', score);
    },
    sendExerciseGettingThereBad() {
      this.$emit('simulation_getting_there', 0, false);
    },

    markSimulation() {
      if (this.currentSimulationExercise === 1) {
        const exercise = this.$refs.exerciseOne;

        if (exercise) {
          exercise.markExercise();
        }
      }

      if (this.currentSimulationExercise === 2) {
        const exercise = this.$refs.exerciseTwo;

        if (exercise) {
          exercise.markExercise();
        }
      }
    },

    getExerciseData() {
      this.exerciseTwoInstructions =
        '<b>Exercise #2</b>: After performing the host identification scan, you need to perform an <b>OS Detection</b> scan to determine what type of operating system each host is running. This helps you identify what each machines purpose is on the network.<br><br>Use the <b>–O</b> (OS detection) argument in <b>nmap</b> to detect the operating system of each device. This is called <b>Operating System Fingerprinting</b> which allows you to gather more information about the network devices that you detected in host discovery.<br><br>As you scan each machine, drag the device type from the repository and match it to the correct IP address.<br><br>Once completed, click "<b>Submit Simulation</b>" to finalize each exercise and view your results.';
      return [this.totalExercises, this.totalExerciseScores, this.exerciseNames, [this.exerciseTwoInstructions]];
    },
  },
  components: {
    draggable,
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    exerciseOneComponent() {
      // eslint-disable-next-line
      return () => import(`./Exercises/Lgx175892346712893728/1-175461239857624831.vue`);
    },
    exerciseTwoComponent() {
      // eslint-disable-next-line
      return () => import(`./Exercises/Lgx175892346712893728/2-175726384910572349.vue`);
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.wordBank {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
}
</style>
